import jspreadsheet, { CellValue} from 'jspreadsheet-ce';
import { incrementPos, formatter, getTable } from './Tool';

var doNotChange = false;
var editionStarted = false;
var onlyAfter = false;

export function cleanValueInput(value: string) {
  if (value == '') {
    return 0
  } else if(value.includes(',')){
      var newValue = value.replaceAll('.', '');
      newValue = newValue.replaceAll(',', '.');
      return(formatter.format(Number(newValue)));
    } else {
      return(formatter.format(Number(value)));
    }
}

export function getCleanValue(sheet: any, col: any, row: any){
  var value = sheet.getCellFromCoords(Number(col), Number(row)).innerHTML;
  value = value == undefined ? '' : value;
  if(value.includes(',')){
    var newValue = value.replaceAll('.', '');
    newValue = newValue.replace(',', '.');
    return isNaN(Number(newValue))? 0 : Number(newValue);
  } else {
    return isNaN(Number(value)) ? 0 : Number(value);
  }
}


export const onbeforechange = function(instance: any, cell: any, col: any, row: any, value: any){
  if ((col == 1 || col == 6 ||col == 7 || col == 100  ) && !(doNotChange)) {
    var newValue = value;
    if (value.toString().includes(',')) {
      if(value.toString().includes('.')){
        newValue = value.replaceAll('.', '');
      }
        newValue = newValue.replaceAll(',', '.');

    } else if (/^\s*$/.test(value)){
      newValue = 0;
    }
    return newValue;
  }
};

const getVerrechnungslohn = () => {
  const cell = document.getElementById('editable-value') as HTMLInputElement;
  let value = (cell)?.value;
  if(value.includes(',')){
    var newValue = value.replaceAll('.', '');
    newValue = newValue.replace(',', '.');
    return isNaN(Number(newValue))? 0 : Number(newValue);
  } else {
    return isNaN(Number(value)) ? 0 : Number(value);
  }

  }

  function getAssociatedTitleRow(changedRow: any) {
    // Assuming you have an identifiable container around your spreadsheet
    let container = document.querySelector('#tool')!; 

    // Get the tr elements inside the container
    let rows = Array.from(container.querySelectorAll('tr'));

    // Start with the changed row's tr element
    let trElement: Element = rows[changedRow];

    // Traverse upwards to find the nearest previous row with class 'title'
    while (trElement !== null && !trElement.classList.contains('title')) {
        trElement = trElement.previousElementSibling!;
    }

    if (!trElement) {
        console.error('Keine Überschrift für diese Leistung gefunden');
        return null;
    }

    // Return the row index of the found title
    return rows.indexOf(trElement as HTMLTableRowElement);
}



function updateAssociatedTitleSum(sheet: any, changedRow: any) {
    let titleRow = getAssociatedTitleRow(changedRow);

    if (titleRow === null) return;

    // Calculate the sum for elements associated with this title
    let total = 0;
    let currentRow = titleRow + 1;
    while (currentRow <= sheet.getData().length && !Number.isInteger(parseFloat(getTable[currentRow-1].position))) {
        let elementValue = getCleanValue(sheet, 4, currentRow-1) || 0;
        total += elementValue;
        currentRow++;
    }
    // Update the title row with the calculated sum
    sheet.setValueFromCoords(5, titleRow-1, formatter.format(total), true);
}


function getAfterMoveAssociatedTitleRow(movedRow: any) {
  let container = document.querySelector('#tool')!; 

    // Get the tr elements inside the container
    let rows = Array.from(container.querySelectorAll('tr'));

    var currentRow = Number(movedRow);
    
    // Start with the changed row's tr element
    let trElement = rows[Number(movedRow)+1];
    console.log(`movedRow: ${movedRow}, length rows: ${rows.length}`)
    if(trElement.classList[0] == "title"){
      currentRow += 1;
    }

    // Traverse upwards to find the nearest previous row with class 'title'
    for(let i = currentRow;i>=0; i-- ){
      if(rows[i].classList[0]=='title'){
        return i;
      }
    }
}

function updateAfterMoveAssociatedTitleSum(sheet: any, oldPos: any, newPos: any, updatedData: any) {
  let titleRow1 = getAfterMoveAssociatedTitleRow(oldPos)!;
  let titleRow2 = getAfterMoveAssociatedTitleRow(newPos)!;
  let total1 = 0;
  let currentRow = titleRow1 + 1;
  while (currentRow <= sheet.getData().length && !Number.isInteger(parseFloat(updatedData[currentRow-1].position))) {
        let elementValue = getCleanValue(sheet, 4, currentRow-1) || 0;
        total1 += elementValue;
        currentRow++;
    }
    let total2 = 0;
    currentRow = titleRow2 + 1;
    while (currentRow <= sheet.getData().length && !Number.isInteger(parseFloat(updatedData[currentRow-1].position))) {
        let elementValue = getCleanValue(sheet, 4, currentRow-1) || 0;
        total2 += elementValue;
        currentRow++;
    }
  sheet.setValueFromCoords(5, titleRow1-1, formatter.format(total1), true);
  sheet.setValueFromCoords(5, titleRow2-1, formatter.format(total2), true);
  
  
}


const handleMengeUpdates = function(sheet: any, value: any, row: any) {
  const ZeitValue = getCleanValue(sheet, 6, row);
  const EPMValue = getCleanValue(sheet, 7, row);
  const vL = getVerrechnungslohn(); 
  const mengeValue = !value.toString().includes('=') ? value : sheet.executeFormula(value, 1, row);
  const EPValue = sheet.executeFormula(`=(${vL}*${ZeitValue})/60 + ${EPMValue}`, 3, row);
  const GPValue = sheet.executeFormula(`=${EPValue}*${mengeValue}`, 4, row);
  //const EPValue = sheet.executeFormula(3, row, `=(${vL}*${ZeitValue})/60 + ${EPMValue}`);
  sheet.setValueFromCoords(3, row, formatter.format(EPValue), true);
  sheet.setValueFromCoords(4, row, formatter.format(GPValue), true);
  updateAssociatedTitleSum(sheet, row);
}

const handleZeitUpdates =  function (sheet: any, value: any, row: any) {
  const EPMValue = getCleanValue(sheet, 7, row);
  const MengeValue = getCleanValue(sheet, 1, row);
  const vL = getVerrechnungslohn(); 
  const ZeitValue = !value.toString().includes('=') ? value : sheet.executeFormula(value, 6, row);
  const EPValue = sheet.executeFormula(`=(${vL}*${ZeitValue})/60 + ${EPMValue}`, 3, row);
  const GPValue = sheet.executeFormula(`=${EPValue}*${MengeValue}`, 4, row);
  sheet.setValueFromCoords(3, row, formatter.format(EPValue), true);
  sheet.setValueFromCoords(4, row, formatter.format(GPValue), true);
  updateAssociatedTitleSum(sheet, row);
}

const handleEPMUpdates = function(sheet:any, value: any, row: any) {
  const ZeitValue = getCleanValue(sheet, 6, row);
  const MengeValue = getCleanValue(sheet, 1, row);
  const vL = getVerrechnungslohn();
  const EPMValue = !value.toString().includes('=') ? value : sheet.executeFormula(value, 7, row)
  const EPValue = sheet.executeFormula(`=(${vL}*${ZeitValue})/60 + ${EPMValue}`, 3, row);
  const GPValue = sheet.executeFormula(`=${EPValue}*${MengeValue}`, 4, row);
  sheet.setValueFromCoords(3, row, formatter.format(EPValue), true);
  sheet.setValueFromCoords(4, row, formatter.format(GPValue), true);
  updateAssociatedTitleSum(sheet, row);
}

const handleVLUpdates = function(sheet:any, value: any) {
  const data = sheet.getData();
  for(let i = 0; i < data.length; i++) {
    if(data[i][4] != ""){
      const ZeitValue = getCleanValue(sheet, 6, i);
      const MengeValue = getCleanValue(sheet, 1, i);
      const EPMValue = getCleanValue(sheet, 7, i);
      const vL = !value.toString().includes('=') ? value : sheet.executeFormula(value, 100, 100);
      const EPValue = sheet.executeFormula(`=(${vL}*${ZeitValue})/60 + ${EPMValue}`, 3, i);
      const GPValue = sheet.executeFormula(`=${EPValue}*${MengeValue}`, 4, i);
      sheet.setValueFromCoords(3, i, formatter.format(EPValue), true);
      sheet.setValueFromCoords(4, i, formatter.format(GPValue), true);
      updateAssociatedTitleSum(sheet, i);
    }
  }
  
}

const handleZSumme = function (sheet: any, value: any) {
  let sum = 0;
  const titleRows = document.querySelectorAll('tr.title');

  // Map over the NodeList to extract the data-y values and convert them to an array
  const dataYValues = Array.from(titleRows).map(row => (row as HTMLTableRowElement).dataset.y);  
  for(let i = 0; i < dataYValues.length; i++) {
    let index = dataYValues[i];
    const ZSValue = getCleanValue(sheet, 5, index);
    sum += ZSValue;
  }
  const mwst = sum * 0.19;
  const brutto = sum + mwst;
  document.querySelector('.netto-value')!.innerHTML = `${formatter.format(sum)} €`;
  document.querySelector('.mwst-value')!.innerHTML = `${formatter.format(mwst)} €` 
  document.querySelector('.brutto-value')!.innerHTML = `${formatter.format(brutto)} €`  
}

const onchanges = function(instance: any, cell: any, col: any, row: any, value: any) {
    const sheet = instance.jexcel;
  if (sheet !== null && !(doNotChange) && !(onlyAfter)) {
    col = Number(col);
    row = Number(row);
    
    switch(col) {
      
      case 1: cell.innerHTML = cleanValueInput(cell.innerHTML);
              handleMengeUpdates(sheet, value, row);
              break;
      case 5: handleZSumme(sheet, value);
              break;
      case 6: cell.innerHTML = cleanValueInput(cell.innerHTML);
              handleZeitUpdates(sheet, value, row);
              break;
      case 7: cell.innerHTML = cleanValueInput(cell.innerHTML);
              handleEPMUpdates(sheet, value, row);
              break;
      case 100: (document.getElementById('editable-value') as HTMLInputElement)!.value = cleanValueInput((document.getElementById('editable-value') as HTMLInputElement)!.value );
                handleVLUpdates(sheet, value);
                break;
    }
}
} 

const oneditionstart = function(instance: HTMLElement, cell: HTMLTableCellElement, columnIndex: string, rowIndex: string) {
  const sheet = (instance as any).jexcel;
  doNotChange = true;
  onlyAfter = false;
  
  if (Number(columnIndex) > 12 && Number(columnIndex) < 23) {
    const value = sheet.getValue(cell);
    if (value.toString().includes('.')) {
      var newValue = value.toString().replaceAll('.', ',');
      sheet.setValue(cell, newValue, false);
      editionStarted = true;
    }
}
doNotChange = false;
}

const editionend = function (instance: HTMLElement, cell: HTMLTableCellElement, columnIndex: number, rowIndex: number, value: CellValue) {
  const sheet = (instance as any).jexcel;
  if (editionStarted){
    let value = sheet.getValue(cell);
    var newValue = value;
    if (value.toString().includes(',')) {
      if(value.toString().includes('.')){
        newValue = value.toString().replaceAll('.', '');
      }
        newValue = newValue.toString().replaceAll(',', '.');

    }
    sheet.setValue(cell, newValue, false);
    editionStarted = false; 
  }
}

const onselection = function (instance: HTMLElement, startColumnIndex: number, startRowIndex: number, endColumnIndex: number, endRowIndex: number) {

}

const updatePositions = function (table: any[], startIndex: any, endIndex: any) {
  const domElements = document.querySelectorAll(`td[class="jexcel_row"][data-y]`);
  for(let i = startIndex; i < endIndex; i++){
    const dataY = domElements[i].getAttribute('data-y');
    if (dataY) {
      domElements[i].innerHTML = table[i].position;
    }
  }
}


const oninsertrow = function (instance: any, startRowIndex: any, count: any, cells: any, setTableData: any) {

  const getTable = require('./Tool').getTable;


  if (getTable[getTable.length - 1].type === 'title') {
    document.querySelector(`tr[data-y="${getTable.length - 1}"]`)?.classList.add('title');
    for (var i = 1; i < 8; i++) {
      document.querySelector(`td[data-x="${i}"][data-y="${getTable.length - 1}"]`)?.classList.add('readonly');
      document.querySelector(`td[data-x="${i}"][data-y="${getTable.length - 1}"]`)?.classList.add('goblack');
    }
  } else {
    document.querySelector(`tr[data-y="${getTable.length - 1}"]`)?.classList.add('element');
    document.querySelector(`td[data-x="3"][data-y="${getTable.length - 1}"]`)?.classList.add('readonly');
    document.querySelector(`td[data-x="3"][data-y="${getTable.length - 1}"]`)?.classList.add('goblack');
    document.querySelector(`td[data-x="4"][data-y="${getTable.length - 1}"]`)?.classList.add('readonly');
    document.querySelector(`td[data-x="4"][data-y="${getTable.length - 1}"]`)?.classList.add('goblack');
    document.querySelector(`td[data-x="5"][data-y="${getTable.length - 1}"]`)?.classList.add('readonly');
    document.querySelector(`td[data-x="5"][data-y="${getTable.length - 1}"]`)?.classList.add('goblack');
  }
  for (let i = 0; i < getTable.length; i++) {
    document.querySelector(`td[class="jexcel_row"][data-y="${i}"]`)!.innerHTML = getTable[i].position;
  }
}

var moveback = false;
const onmoverow = (element: any, oldPosition: any, newPosition: any, setTableData: any) => {
  const getTable = require('./Tool').getTable;
  const movedRow = getTable[oldPosition];
  const precedingRow = getTable[newPosition - 1];


  if((newPosition == 0 || oldPosition == 0) && moveback == false ) {
    moveback = true;
    element.jexcel.moveRow(newPosition, oldPosition);
    return;
  }

  if (moveback === true){
    const domElements = document.querySelectorAll(`td[class="jexcel_row"][data-y]`);
  for(let i = 0; i < getTable.length; i++){
    const dataY = domElements[i].getAttribute('data-y');
    if (dataY) {
      domElements[i].innerHTML = getTable[i].position;
    }
  }
    moveback = false;
    return;
  }
  else if (movedRow.type === 'title') {
    if (precedingRow && (precedingRow.position !== movedRow.position) &&precedingRow.type === 'title') {
      alert('Eine Leistung dazwischen fehlt.');
      moveback = true;
      element.jexcel.moveRow(newPosition, oldPosition);
      return;
    }
    setTableData((prevTableData: any) => {
      const updatedData = [...prevTableData];
      const removedRow = updatedData.splice(oldPosition, 1)[0];
      updatedData.splice(newPosition, 0, removedRow);
      const minPosition = Math.min(oldPosition, newPosition);
      const maxPosition = Math.max(oldPosition, newPosition);
      var lastIndex = 0;
      for (lastIndex = maxPosition+1; lastIndex < getTable.length; lastIndex++) {
          if (getTable[lastIndex].type === 'title') {
            break;
          }
        }
      for (let i = minPosition; i < lastIndex; i++) {
        if (updatedData[i]) {
          // Update the position of the element
          if (updatedData[i].type === 'element') {
            updatedData[i].position = incrementPos(updatedData[i-1].position);
          } else if (updatedData[i].type === 'title') {
            updatedData[i].position = Number(updatedData[i-1].position.split('.')[0]) + 1;
          }
        }
      }
      const isOldPosMax = maxPosition == oldPosition ? true : false;
      if (isOldPosMax) {
        updateAfterMoveAssociatedTitleSum(element.jexcel, oldPosition, Number(newPosition) - 1, updatedData)
      } else {
        try {
          updateAfterMoveAssociatedTitleSum(element.jexcel, oldPosition, Number(newPosition) + 1, updatedData)
        } catch {
          updateAfterMoveAssociatedTitleSum(element.jexcel, oldPosition, newPosition, updatedData) //newPosiition does not matter but because it is required
          element.jexcel.setValueFromCoords(5, newPosition, formatter.format(0), true);
        }
        
      }
      updatePositions(updatedData, minPosition, lastIndex);
      return updatedData;
    });
  } else if (movedRow.type === 'element') {
    const titleOld = getTable[oldPosition].position.split('.')[0];
    const isPrecedingTitle = Number.isInteger(parseFloat(getTable[oldPosition-1].position)) ? true : false;
    let isAfterTitle;

    try {
      // Attempt to parse and evaluate the position
      isAfterTitle = Number.isInteger(parseFloat(getTable[Number(oldPosition) + 1].position));
    } catch (error) {
      // If an error occurs, log it to the console and set isAfterTitle to false
      console.error(error);
      isAfterTitle = false;
    }
    if(isPrecedingTitle && isAfterTitle) {
      alert('Eine Leistung dazwischen fehlt.');
      moveback = true;
      element.jexcel.moveRow(newPosition, oldPosition);
      return;
    }

    const isTitle = Number.isInteger(parseFloat(getTable[newPosition].position)) ? true: false;
    var titleNew = isTitle
                    ? getTable[newPosition].position 
                    : getTable[newPosition].position.split('.')[0];
    
    if(titleOld === titleNew) {

      const minPosition = Math.min(oldPosition, newPosition);
      const maxPosition = Math.max(oldPosition, newPosition);
      setTableData((prevTableData: any) => {
        const updatedData = [...prevTableData];
        const removedRow = updatedData.splice(oldPosition, 1)[0];
        updatedData.splice(newPosition, 0, removedRow);

      for (let i = minPosition; i <= maxPosition; i++) {
        if (updatedData[i]) {
          // Update the position of the element
          if (updatedData[i].type === 'element') {
            updatedData[i].position = incrementPos(updatedData[i-1].position);
          } 
        }
      }
      updatePositions(updatedData, minPosition, maxPosition+1);
      return updatedData;
      });

    } else {
      const maxPosition = Math.max(oldPosition, newPosition);
      var lastIndex = newPosition == maxPosition ? maxPosition + 1: maxPosition;
      for (lastIndex; lastIndex < getTable.length; lastIndex++) {
          if (getTable[lastIndex].type === 'title') {
            break;
          }
        }
      
      // Update tableData using setTableData
      setTableData((prevTableData: any) => {
        const updatedData = [...prevTableData];
        const removedRow = updatedData.splice(oldPosition, 1)[0];
        updatedData.splice(newPosition, 0, removedRow);
  
      
      // Update the positions of the rows between old and new positions
      const minPosition = Math.min(oldPosition, newPosition);
      
      for (let i = minPosition; i < lastIndex; i++) {
        if (updatedData[i]) {
          // Update the position of the element
          if (updatedData[i].type === 'element') {
            updatedData[i].position = incrementPos(updatedData[i-1].position);
          } 
        }
      }
      if (updatedData[oldPosition].type == 'title') {
        const isoldMax = oldPosition == maxPosition ? true : false;
        if (isoldMax) {
          try{
            updateAfterMoveAssociatedTitleSum(element.jexcel, Number(oldPosition) + 1, newPosition, updatedData);
          } catch (error) {
            element.jexcel.setValueFromCoords(5, oldPosition, formatter.format(0), true);
          }

        } else {
          updateAfterMoveAssociatedTitleSum(element.jexcel, Number(oldPosition) - 1, newPosition, updatedData);
        }
      } else {
        updateAfterMoveAssociatedTitleSum(element.jexcel, oldPosition, newPosition, updatedData);
      }
      
      updatePositions(updatedData, minPosition, lastIndex);
      return updatedData;
      });
      
    }
    

  }
};

const updateAfterDeleteAssociatedTitleSum = function (sheet: any, updatedData: any, changedRow: any) {
    let titleRow = getAssociatedTitleRow(changedRow);

    if (titleRow === null) return;

    // Calculate the sum for elements associated with this title
    let total = 0;
    let currentRow = titleRow + 1;
    while (currentRow <= sheet.getData().length && !Number.isInteger(parseFloat(updatedData[currentRow-1].position))) {
        let elementValue = getCleanValue(sheet, 4, currentRow-1) || 0;
        total += elementValue;
        currentRow++;
    }
    // Update the title row with the calculated sum
    sheet.setValueFromCoords(5, titleRow-1, formatter.format(total), true);
}

const ondeleterow = function(sheet: any, rowIndex: number, numOfRows: number, deletedCells: any[], setTableData: any) {
  if(getTable[rowIndex].type == 'title') {
    setTableData((prevTableData: any) => {
      const updatedData = [...prevTableData];
      updatedData.splice(rowIndex, 1);
    for (let i = rowIndex; i < updatedData.length; i++) {
      if (updatedData[i]) {
          updatedData[i].position = incrementPos(updatedData[i-1].position);
      }
    }
    updatePositions(updatedData, rowIndex, updatedData.length);
    updateAfterDeleteAssociatedTitleSum(sheet.jexcel, updatedData,Number(rowIndex) - 1);
    

    return updatedData;
    });
  } 
  else if(getTable[rowIndex].type == 'element') {
    setTableData((prevTableData: any) => {
      const updatedData = [...prevTableData];
      updatedData.splice(rowIndex, 1);
      for (let i = rowIndex; i < updatedData.length; i++) {
        if (updatedData[i]) {
          if (updatedData[i].type == 'title') {
            break;
          }
          updatedData[i].position = incrementPos(updatedData[i-1].position);
        }
      }
    updatePositions(updatedData, rowIndex, updatedData.length);

    let typeReplaced;

    try {
      typeReplaced = updatedData[rowIndex].type;
      if (typeReplaced == 'title') {
        updateAssociatedTitleSum(sheet.jexcel, Number(rowIndex) - 1);
      }
     else if (typeReplaced == 'element') {
        updateAssociatedTitleSum(sheet.jexcel, rowIndex)
    
       }
      } catch {
        if (updatedData[Number(rowIndex) - 1].type == 'title') {
          sheet.jexcel.setValueFromCoords(5, Number(rowIndex) - 1, formatter.format(0), true);
        } else if (updatedData[Number(rowIndex) - 1].type == 'element') {
          updateAssociatedTitleSum(sheet.jexcel, Number(rowIndex) - 1);
        }
      }
  
    return updatedData;
    });
  }

}





const cols = [
  { width: 350 },
  { width: 80 },
  { width: 80 },
  { width: 90 },
  { width: 90 },
  { width: 150 },
  { width: 90 },
  { width: 150 }
]
export function initializeSheet(
    toolRef: NonNullable<React.RefObject<HTMLDivElement>>,
    setTableData: any,
  ) {
    return jspreadsheet(toolRef.current as NonNullable<HTMLDivElement>, {
          minDimensions: [8, 2],
          allowInsertRow: true,
          allowManualInsertColumn: false,
          allowManualInsertRow: false,
          columns: cols,
          defaultRowHeight: 40,
          copyCompatibility: false,
          parseFormulas: true,
          onbeforechange: onbeforechange,
          onchange: onchanges,
          oneditionstart: oneditionstart,
          oneditionend: editionend,
          onselection: onselection,
          oninsertrow: (instance, startRowIndex, count, cells) => oninsertrow(instance, startRowIndex, count, cells, setTableData),
          onmoverow: (element, oldPosition, newPosition) => onmoverow(element, oldPosition, newPosition, setTableData),
          ondeleterow: (element, rowIndex, numOfRows, deletedCells) => ondeleterow(element, rowIndex, numOfRows, deletedCells, setTableData),
        });
  }