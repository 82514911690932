import React from "react";
import './info.css'


const Info = (width) => {

    return (
<div className="text" style={{ maxWidth: `${width['width']}px` }}>
    <p><strong>Kleine Einweisung zu unserem kostenlosen Kalkulations-Tool:</strong></p>

    <p>Unter Verrechnungslohn geben Sie Ihren “Verrechnungslohn“ ein. Wenn Sie diese Voreinstellung vorgenommen haben, können Sie mit der Kalkulation loslegen:</p>

    <p>Sie können beliebig viele Positionen erstellen - als Kurz- und/oder Langtext. Die Positionsnummern werden automatisch vergeben. Sie müssen lediglich angeben ob es sich bei der nächsten Zeile um eine Überschrift oder um eine Leistung handelt.</p>

    <p>Nachdem Sie die “Menge“ und die “Einheit“ eingegeben haben müssen Sie die benötigte Zeit eingeben.</p>

    <p>Tragen Sie dann zum Schluss noch den Preis für die Material- und/oder Entsorgungskosten, ebenfalls pro Einheit, unter der Spalte “Stoffe“ ein. Unser Tool errechnet Ihnen danach den EP (Einheitspreis) für Ihre Leistung und rechnet ihnen alles zu Ihren angegebenen Mengen aus. Wenn Sie fertig sind, können Sie Ihr Angebot einfach ausdrucken und/oder als PDF exportieren.</p>

    <h3>Hinweise</h3>

        <p>Für komplexere Kalkulationen stehen wir Ihnen wie oben bereits erwähnt gerne zur Verfügung. Wir sind, wie Sie aus unserer Webseite sicherlich schon raus lesen konnten, auf die Kalkulationen von öffentlichen Ausschreibungen spezialisiert und können entweder projektbezogen oder bei Bedarf alle Ihre Kalkulationen durchführen.</p>

        <p>Demnächst bieten wir diesen Part der einfachen Online-Kalkulation zusätzlich mit der (kostenpflichtigen) Möglichkeit an, dass Sie Ihre Kalkulationen als Vorlage abspeichern und auch mit Ihrem Briefkopf versehen können. Wenn Sie daran interessiert sind, teilen Sie uns gerne schon vorab Ihr Interesse hierüber mit. Sobald wir hierzu Neuerungen haben, informieren wir Sie natürlich gerne.</p>

    <h4>*Verrechnungslohn:</h4>

        <p>In Ihrem Verrechnungslohn müssen Sie Ihre Lohn- und Lohnnebenkosten, allgemeine Geschäftskosten, Maschinenkosten und natürlich auch Ihren Gewinn berücksichtigen. *evtl. noch weitere Infos hierzu</p>

    <h4>**Menge:</h4>

        <p>Um ein gewissenhaftes Angebot erstellen zu können, sollten Sie die Menge zur jeweils zu erbringenden Leistung kennen. Entweder machen Sie hierzu ein Aufmaß vor Ort oder Sie bekommen diese Angaben von/vom ihrem/ihrer Auftraggeber/in.</p>

    <h4>***Einheit:</h4>

        <p>Ihre angegebene Zeitangabe bezieht sich immer auf die jeweilige Einheit, z.B.: pro Meter, m², m³ usw.</p>

</div>
    )
}


export default Info;