import React, { useState } from 'react';
import Tool from './components/Tool';
import './App.css';
import Info from './components/Info';
import { FaArrowRight } from 'react-icons/fa';

function App() {
  
  const [width, setWidth] = useState(0);
  const buttonStyle = {
    position: 'fixed',
    backgroundColor: '#007bff', // Blue background
    marginTop: '20px',
    color: 'white', // White text
    cursor: 'pointer', // Pointer cursor on hover
    textDecoration: 'none', // Remove underline from links
    border: '1px solid #cccccc',
    fontFamily: 'Poppins, sans-serif',
    padding: '8px 15px',
    borderRadius: '5px',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  };
  const buttonContainerStyle = {
    display: 'flex', // Use flexbox
    justifyContent: 'flex-end', // Align items to the right
    marginRight: '50px',
    width: '100%', // Ensure the container spans the full width
  };

  const iconStyle = {
    marginLeft: '10px',
  }

  return (
    <div id="parent-container">
      <div style={buttonContainerStyle}>
      <button
        style = {buttonStyle}
        onClick={() => window.location.href = 'https://kalku.de'}
        >
          Weiter zur Kalku.de
          <FaArrowRight style={iconStyle}/>
        </button>
      </div>
      <div className="tool">
        <Tool
        setWidth = {setWidth}
        />
      </div>
      <Info width={width}/>
      </div>
  );
}

export default App;
