import React, { useEffect, useRef, useState} from 'react';
import { initializeSheet, onbeforechange } from './initializeTool.ts';
import { PDFDocument, rgb } from 'pdf-lib';
import './tool.css';
import * as fontkit from '@btielen/pdf-lib-fontkit';

export const formatter = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export var getTable = [];

export var onmoverow =  (
  element,
  oldPosition,
  newPosition
) => {
};

export const Decimal = require('decimal.js');

export const incrementPos = (pos) => {
  const increment = new Decimal('0.001');
  const position = new Decimal(pos);
  return position.plus(increment).toFixed(3).toString();
}




const Tool = ({
  setWidth,
}) => {

  const toolRef = useRef(null);
  const [tableData, setTableData] = useState([
    {type: 'title', position: 1},
    {type: 'element', position: '1.001'}
  ]);


  toolRef.current?.addEventListener('contextmenu', function(event) {
    event.preventDefault();
    event.stopPropagation();
  });

  useEffect(() => {

    if (toolRef.current) {
      toolRef.current.innerHTML = '';
      getTable = [];
      const sheet =  initializeSheet(toolRef, setTableData);
      if (sheet !== null){
        const isWiderThanWindow = 1184 > (window.innerWidth - 10);
        if (isWiderThanWindow) {
          const container = document.querySelector('.jexcel_content');
          container.style.overflowX = 'auto';
          container.style.width = `${window.innerWidth - 10}px`;
        }

        for (var i = 0; i < 2; i++) {
          document.querySelector(`td[class="jexcel_row"][data-y="${i}"]`).innerHTML = tableData[i].position;
        } 
        document.querySelector('td[class="jexcel_selectall"]').innerHTML = "Position";
        document.querySelector('colgroup col:first-child').setAttribute('width', 90);
        document.querySelector('thead tr td[data-x="0"]').innerHTML = "Bezeichnung";
        document.querySelector('thead tr td[data-x="1"]').innerHTML = "Menge";
        document.querySelector('thead tr td[data-x="2"]').innerHTML = "Einheit";
        document.querySelector('thead tr td[data-x="3"]').innerHTML = "Ep[€]";
        document.querySelector('thead tr td[data-x="4"]').innerHTML = "Gp[€]";
        document.querySelector('thead tr td[data-x="5"]').innerHTML = "Zwichensumme[€]";
        document.querySelector('thead tr td[data-x="6"]').innerHTML = "Zeit[min]";
        document.querySelector('thead tr td[data-x="7"]').innerHTML = "EP Material [€]";
        
        document.querySelector(`tr[data-y="0"]`)?.classList.add('title');
        document.querySelector(`tr[data-y="0"]`)?.classList.add('undraggable');
        document.querySelector(`tr[data-y="1"]`)?.classList.add('element');
        for (i = 1; i < 8; i++) {
          document.querySelector(`td[data-x="${i}"][data-y="0"]`)?.classList.add('readonly');
          document.querySelector(`td[data-x="${i}"][data-y="0"]`)?.classList.add('goblack');      
        }
        document.querySelector(`td[data-x="3"][data-y="1"]`)?.classList.add('readonly');
        document.querySelector(`td[data-x="3"][data-y="1"]`)?.classList.add('goblack');
        document.querySelector(`td[data-x="4"][data-y="1"]`)?.classList.add('readonly');
        document.querySelector(`td[data-x="4"][data-y="1"]`)?.classList.add('goblack');
        document.querySelector(`td[data-x="5"][data-y="1"]`)?.classList.add('readonly');
        document.querySelector(`td[data-x="5"][data-y="1"]`)?.classList.add('goblack');

        setWidth(document.querySelector('.jexcel_content').clientWidth)
        

        
        
      }
      
    }
  }, []);

   useEffect(() => {
    getTable = [];
    getTable = tableData;
  }, [tableData]); 




  window.addEventListener('resize', function() {
    const container = document.querySelector('.jexcel_content');
    const isWiderThanWindow =  window.innerWidth < 1184;
    container.style.overflowX = isWiderThanWindow? 'auto': 'hidden';
    container.style.width = isWiderThanWindow? `${window.innerWidth - 10}px` : '1184px';

  });


  async function createPDF_calc(pdata) {

    const doc = await PDFDocument.create();
    doc.registerFontkit(fontkit);
    let page = doc.addPage([595.28, 841.89]);
    let pagenumber = 1
    const font = await doc.embedFont('Helvetica');
    const bold = await doc.embedFont('Helvetica-Bold');

    const verticalSpacing = 20;
    const startY = page.getHeight() - 50; // Adjust initial Y position to fit content on page
    const grossY = startY - 2 * verticalSpacing;
    // Drawing lines for the offer sum details
    page.drawLine({ start: { x: 350, y: startY }, end: { x: 570, y: startY } });
    page.drawLine({ start: { x: 350, y: startY - 2 * verticalSpacing }, end: { x: 570, y: startY - 2 * verticalSpacing } });
    page.drawLine({ start: { x: 350, y: startY }, end: { x: 350, y: startY - 2 * verticalSpacing } });
    page.drawLine({ start: { x: 570, y: startY }, end: { x: 570, y: startY - 2 * verticalSpacing } });


    const nsumme = document.querySelector('.netto-value').innerText;
    const mwst = document.querySelector('.mwst-value').innerText;
    const bsumme = document.querySelector('.brutto-value').innerText;
    // Net Offer Sum
     page.drawText('Netto Angebotssumme:', { x: 352, y: startY - 8, font: bold, size: 8 });
    page.drawText( nsumme, { x: 510, y: startY - 8, font: bold, size: 8, color: rgb(0, 0, 0) });

    // MwSt (tax)
    page.drawText('19% MwSt:', { x: 352, y: startY - verticalSpacing, font: bold, size: 8 });
    page.drawText(mwst, { x: 510, y: startY - verticalSpacing, font: bold, size: 8, color: rgb(0, 0, 0) });

    // Gross Offer Sum
    page.drawText('Brutto Angebotssumme:', { x: 352, y: grossY + 8, font: bold, size: 8 });
    page.drawText(bsumme, { x: 510, y: grossY + 8, font: bold, size: 8, color: rgb(0, 0, 0) });

    // Additional Spacing
    const nextY = grossY - 20; // Adjust as needed based on your content

    // Drawing the lines for the table headers
    page.drawLine({ start: { x: 10, y: nextY + 6}, end: { x: 570, y: nextY + 6} });
    page.drawLine({ start: { x: 10, y: nextY - 14 }, end: { x: 570, y: nextY - 14 } });
    page.drawLine({ start: { x: 10, y: nextY + 6 }, end: { x: 10, y: nextY - 14 } });
    page.drawLine({ start: { x: 570, y: nextY + 6 }, end: { x: 570, y: nextY - 14 } });

    // Table Headers
    page.drawText('Pos.', { x: 15, y: nextY - 5, font: bold, size: 8 });
    page.drawText('Bezeichnung', { x: 80, y: nextY - 5, font: bold, size: 8 });
    page.drawText('Menge', { x: 350, y: nextY - 5, font: bold, size: 8 });
    page.drawText('EP in [€ ]', { x: 420, y: nextY - 5, font: bold, size: 8 });
    page.drawText('GP in [€ ]', { x: 490, y: nextY - 5, font: bold, size: 8 });

    let footerText = `Seite ${pagenumber}`;
    page.drawText(footerText, {
      x: 15,               // Set the x position
      y: 20,               // Set the y position (30 units up from the bottom of the page)
      size: 6,            // Set font size
      // font: yourFont,   // Optionally set a font
      color: rgb(0, 0, 0)  // Set color to black (can be adjusted as needed)
    });

    const kalkuText = "Powered by kalku.de";
    const textWidth = font.widthOfTextAtSize(kalkuText, 6);
    const xCenteredKalku = (page.getWidth() - textWidth) / 2;

    page.drawText(kalkuText, {
      x: xCenteredKalku,               // Set the x position
      y: 20,               // Set the y position (30 units up from the bottom of the page)
      size: 6,            // Set font size
      // font: yourFont,   // Optionally set a font
      color: rgb(0, 0, 0)  // Set color to black (can be adjusted as needed)
    });
    // Loop through pdata for the table content
    const sheet = toolRef.current.jexcel.getData();
    let currentY = nextY - 50;
    pdata.forEach((item, index) => {
      currentY -= 20; // adjust based on your required spacing
      let bezeichnungsText = sheet[index][0].toString().replace(/[^a-zA-Z0-9]/g, 'a');
      const bezeichnungWidth = font.widthOfTextAtSize(bezeichnungsText, 8);
      const lines = Math.ceil(bezeichnungWidth / 250);
      if (currentY < 50 || currentY - lines*20 < 50) {
        page = doc.addPage([595.28, 841.89]);
        pagenumber += 1;
        let footerText = `Seite ${pagenumber}`;
        page.drawText(footerText, {
          x: 15,               // Set the x position
          y: 20,               // Set the y position (30 units up from the bottom of the page)
          size: 6,            // Set font size
          // font: yourFont,   // Optionally set a font
          color: rgb(0, 0, 0)  // Set color to black (can be adjusted as needed)
        });
        page.drawText(kalkuText, {
          x: xCenteredKalku,               // Set the x position
          y: 20,               // Set the y position (30 units up from the bottom of the page)
          size: 6,            // Set font size
          // font: yourFont,   // Optionally set a font
          color: rgb(0, 0, 0)  // Set color to black (can be adjusted as needed)
        });
        currentY = page.getHeight() - 50;

      }
        page.drawText(item.position.toString(), { x: 15, y: currentY, font, size: 8 });
        page.drawText(sheet[index][0], { x: 80, y: currentY, font, size: 8, maxWidth: 250, lineHeight: 20 });
        page.drawText(sheet[index][1].toString(), { x: 350, y: currentY, font, size: 8 });
        page.drawText(sheet[index][3], { x: 420, y: currentY, font, size: 8 });
        page.drawText(sheet[index][4], { x: 490, y: currentY, font, size: 8 });
        page.drawText(sheet[index][5], { x: 540, y: currentY, font, size: 8 });
        if (lines > 0) {
          currentY = currentY - (lines * 20);
        }
    });

    const pdfBytes = await doc.save();
    return pdfBytes;
}


async function downloadPDF() {
  const pdfBytes = await createPDF_calc(tableData);

  const blob = new Blob([pdfBytes], { type: 'application/pdf' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'output.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

 
    
  const onTitleClick = async () => {
    const lastItem = getTable[getTable.length - 1];

    // Check if the last item is a title
    if (lastItem && lastItem.type === 'title') {
      alert("Fehler: Überschriften dürfen nicht von anderen Überschriften vorangestellt werden.");
      // Return the current table data without changes

    } else {
      await setTableData((prevTableData) => {
        const lastTitleIndex = prevTableData
          .slice()
          .reverse()
          .findIndex((item) => item.type === 'title');
    
        // Calculate the new title position based on the last title's position
        const lastTitlePosition = lastTitleIndex !== -1 ? prevTableData[prevTableData.length - lastTitleIndex - 1].position : 0;
        const newPosition = lastTitlePosition + 1;
    
        const newTitle = { type: 'title', position: newPosition };
    
        const updatedTableData = [...prevTableData, newTitle];
        getTable = updatedTableData;
        return updatedTableData;
      });
      toolRef.current.jexcel.insertRow();
    }
    
  };
  
  const handleKeyDownVL = function (event) {
    if (event.keyCode === 13 || event.keyCode === 9) {
      // Enter key pressed
      event.preventDefault();
      event.stopPropagation();
      const editableCell = document.getElementById('editable-cell');
      const editableValue = document.getElementById('editable-value');
      editableCell.blur();
      editableValue.blur();
      const newValue = onbeforechange(toolRef.current.jexcel, editableCell, 100, 100, editableValue.value);
    // Trigger the onchange event with the new value
      toolRef.current.jexcel.dispatch('onchange', toolRef.current, editableCell, 100, 100, newValue);

    }
  }

  // DELETE BUTTON PROCEED: FIX NMUBERING; ALSO DO NOT FORGET TO UPDATE TABLEDATA
  const handleKeyDown = async (event) => {
    const sheet = toolRef.current.jexcel;
    const { selectedCell } = sheet;
    const selectedCells = document.querySelectorAll('.selected');
    const sCell = sheet.getCellFromCoords(Number(selectedCell[0]), selectedCell[1]);
    const row = sCell.closest('tr');
    const element = document.getElementById('tool');


    if (event.keyCode === 13) {
      if ((selectedCell[1] == sheet.getData().length - 1)&&sheet.edition === null){
        event.preventDefault();
        event.stopPropagation();
        await onElementClick();
        const tobeSCell = document.querySelector(`td[data-x="0"][data-y="${Number(selectedCell[1])+1}"]`);
        sheet.updateSelection(tobeSCell);
      } else {
        element.focus();
      }

    } else if ((event.keyCode === 8 && event.metaKey)  || event.keyCode === 46) {
      const difference = Number(selectedCell[2]) - Number(selectedCell[0]);
      
      if (selectedCell[1] == 0) {
        alert('Diese Überschrift kann man nicht löschen');
        return;
      }
      const isElement = sCell.parentNode.classList.contains('element');
      const isLastElement = Number(selectedCell[1]) == tableData.length - 1;

      if (isElement && !isLastElement) {
        const isPrevCellTitle = sheet.getCellFromCoords(Number(selectedCell[0]), Number(selectedCell[1]) - 1).parentNode
                                  .classList.contains('title');

        const isAfterCellTitle = sheet.getCellFromCoords(Number(selectedCell[0]), Number(selectedCell[1]) + 1).parentNode
                                  .classList.contains('title');

        if (isPrevCellTitle && isAfterCellTitle) {
          alert('Überschriften dürfen nicht von anderen Überschriften vorangestellt werden.');
          return;
        }
        
      }
      if (difference === 7 && selectedCell[1] == selectedCell[3]) {
        if(sheet.edition !== null) {
          sheet.closeEditor(sCell, true);
        }
        sheet.deleteRow(Number(selectedCell[1]), 1);
      }
    }
    else if (event.keyCode === 9) {
      console.log('I stopped propagation'); 
      if(selectedCell[0] === 7) {
        if (((selectedCell[1] == sheet.getData().length - 1) && sheet.edition != null)){
          element.focus();
          return;
        }
        
        
        event.stopPropagation();
        event.preventDefault();
        if ((selectedCell[1] == sheet.getData().length - 1)){
          await onElementClick();
        }
        const tobeSCell = document.querySelector(`td[data-x="0"][data-y="${Number(selectedCell[1])+1}"]`);
        if(sheet.edition !== null) {
          sheet.closeEditor(sCell, true);
          element.focus();
        }
        sheet.updateSelection(tobeSCell);
      }
      if(row.classList.contains('title')){
        event.preventDefault();
        event.stopPropagation();
        if(sheet.edition !== null) {
          sheet.closeEditor(sCell, true);
          element.focus();

        }
        sheet.updateSelectionFromCoords(selectedCell[0], Number(selectedCell[1])+1);
        element.focus();
    
      } else if (row.classList.contains('element')) {
        if(Number(selectedCell[0]) === 2) {
          event.preventDefault();
          event.stopPropagation();
          sheet.updateSelectionFromCoords(6, Number(selectedCell[1]));
          if(sheet.edition !== null) {
            sheet.closeEditor(sCell, true);
            element.focus();
          }
          
        }
        element.focus();
      }
    }
    else if (event.keyCode === 8 && selectedCells.length > 2) {
      const indexX0 = Number(selectedCell[0]);
      const indexX1 = Number(selectedCell[2]);
      const indexY0 = Number(selectedCell[1]);
      const indexY1 = Number(selectedCell[3]);

      const delta = (indexX1 - indexX0) + (indexY1 - indexY0);

      if (delta != 0) {
        event.preventDefault();
        event.stopPropagation();
  
        const highlights = document.querySelectorAll('.highlight');
        const highlightlefts = document.querySelectorAll('.highlight-left');
        const highlightrights = document.querySelectorAll('.highlight-right');
        const highlighttops = document.querySelectorAll('.highlight-top');
        const highlightbottoms = document.querySelectorAll('.highlight-bottom');
        const selecteds = document.querySelectorAll('.selected');
        highlights.forEach((highlightedCell) => {
          highlightedCell.classList.remove('highlight');
        });
        highlightlefts.forEach((highlightedCell) => {
          highlightedCell.classList.remove('highlight-left');
        });
        highlightrights.forEach((highlightedCell) => {
          highlightedCell.classList.remove('highlight-right');
        });
        highlighttops.forEach((highlightedCell) => {
          highlightedCell.classList.remove('highlight-top');
        });
        highlightbottoms.forEach((highlightedCell) => {
          highlightedCell.classList.remove('highlight-bottom');
        });
        selecteds.forEach((selected) => {
          selected.classList.remove('selected');
        });
  
        const corner = document.querySelector('.jexcel_corner');
        setTimeout( () => {
          corner?.setAttribute('style', `display:none;`);
        }, 10);

        // Determine the starting and ending x coordinates
        const startX = Math.min(indexX0, indexX1);
        const endX = Math.max(indexX0, indexX1);

        // Determine the starting and ending y coordinates
        const startY = Math.min(indexY0, indexY1);
        const endY = Math.max(indexY0, indexY1);

        // Loop through the range of selected cells
        for (let x = startX; x <= endX; x++) {
            for (let y = startY; y <= endY; y++) {
                // Set the value of the cell to an empty string
                sheet.setValueFromCoords(x, y, '');
            }
        }


      }
      
      
    }
  }


const onElementClick = async () => {
  await setTableData((prevTableData) => {
    const newPosition = prevTableData[prevTableData.length - 1].type === 'title'
      ? `${prevTableData[prevTableData.length - 1].position}.001`
      : incrementPos(prevTableData[prevTableData.length - 1].position);
    const newElement = { type: 'element', position: newPosition };
    const updatedTableData = [...prevTableData, newElement];
    getTable = updatedTableData;
    return updatedTableData;
  });
  toolRef.current.jexcel.insertRow();
};


  


  return (
    <div>
     <div className='header'>
      <table className="custom-table">
        <tbody>
          <tr>
            <td className="read-only">Verrechnungslohn: </td>
            <td id="editable-cell" className='euro'>
                <input 
                    id="editable-value" 
                    onKeyDown={handleKeyDownVL} 
                    defaultValue={formatter.format(49.9)} 
                />
            </td>
          </tr>
        </tbody>
      </table>
      <table className='summe'>
        <tbody>
        <tr>
          <td className='netto-title'>Netto Angebotssumme:</td>
          <td className='netto-value'></td>
        </tr>
        <tr>
          <td className='mwst-title'>19% MwSt:</td>
          <td className='mwst-value'></td>
        </tr>
        <tr>
          <td className='brutto-title'>Brutto Angebotssumme:</td>
          <td className='brutto-value'></td>
        </tr>
        </tbody>
      </table>
      </div>
      {/* Your React component */}
      <div ref={toolRef} id="tool" onKeyDown={handleKeyDown} />
      <div className="tool-container">
        <div className="left-buttons">
            <button className='toolbutton' onClick={onElementClick}>Neue Leistung</button>
            <button className='toolbutton' onClick={onTitleClick}>Neue Überschrift</button>
        </div>
        <button className='align-right toolbutton' onClick={downloadPDF}>PDF Erstellen</button>
    </div>
    </div>
  );
  
  
  
  
}



export default Tool;
